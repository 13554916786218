<template>
  <div style="overflow: hidden">
    <v-row>
      <v-col cols="4">
        <DailyReservationList class="ma-2" :bus="dailyBus">
        </DailyReservationList>
      </v-col>
      <v-col cols="4">
        <CurrentReservation
          @reload-reservations="handleRecalcBadge"
          class="ma-2"
          :bus="dailyBus"
        >
        </CurrentReservation>
      </v-col>
      <v-col cols="4">
        <BarPayment class="ma-2" :bus="dailyBus"></BarPayment>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import CurrentReservation from "@/components/bar/daily-reservation/CurrentReservation.vue";
import DailyReservationList from "@/components/bar/daily-reservation/DailyReservationList.vue";
import BarPayment from "@/components/bar/daily-reservation/BarPayment.vue";
import Vue from "vue";

export default {
  components: {
    BarPayment,
    CurrentReservation,
    DailyReservationList,
  },
  data() {
    return {
      localSelectedBill: {},
      dailyBus: new Vue(),
    };
  },
  methods: {
    handleRecalcBadge() {
      this.dailyBus.$emit("reload-reservations");
    },
  },
};
</script>

<style>
</style>