<template>
  <div style="overflow-x:hidden; overflow-y:hidden;" class="ml-2">
    <v-row no-gutters>
      <v-col cols="8">
        <MenuList
          :menu-counter.sync="menuCounter"
          :delete-menu="deletableMenu"
          :disable-menu="disableMenu"
          :bus="bus"
        ></MenuList>
      </v-col>
      <v-col cols="4">
        <RightMenuSettingMenu
          :min-number-menu="menuCounter <= 0"
          @add-menu="handleOpenDialogCreateMenu"
          @deletable-menu="handleDeletableMenu"
          @change-color="handleChangeColor"
        ></RightMenuSettingMenu>
      </v-col>
    </v-row>
    <MenuCreateDialog
      v-if="openCreateMenu"
      :open-dialog="openCreateMenu"
      :menu-selected-color="colorSelected"
      @close="close"
      @saved-menu="handleSaveMenu">
    </MenuCreateDialog>
  </div>
</template>

<script>
import MenuList from "@/components/bar/settings/menu/MenuList.vue";
import MenuCreateDialog from "@/components/bar/settings/menu/new/MenuCreateDialog.vue";
import RightMenuSettingMenu from "@/components/bar/settings/menu/RightMenuSettingMenu.vue";
import Vue from 'vue';

export default {
  name: "MenuManagementMinimalized",
  components: {
    MenuList,
    MenuCreateDialog,
    RightMenuSettingMenu,
  },
  data() {
    return {
      openCreateMenu: false,
      deletableMenu: false,
      disableMenu: false,
      menuCounter: 0,
      colorSelected: "",
      bus: new Vue(),
    }
  },
  methods: {
    close() {
      this.menuCounter--;
      this.openCreateMenu = false;
    },
    handleOpenDialogCreateMenu() {
      this.menuCounter++;
      this.openCreateMenu = true;
    },
    handleRemoveMenu() {
      this.bus.$emit("remove-last-menu");
    },
    handleDeletableMenu(value) {
      this.deletableMenu = value;
    },
    handleChangeColor(newColor) {
      this.colorSelected = newColor;
    },
    handleSaveMenu() {
      this.openCreateMenu = false;
      this.bus.$emit('reload-menus');
    }
  },
}
</script>