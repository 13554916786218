<template>
  <div>
    <StandardDialog
      dialog-width="calc(90vh-100px)"
      dialog-height="calc(90vh-100px)"
      persistent
      v-model="localOpenDialog"
      :title="'Prenotazione Tavolo ' + tableCode + ' in ' + tableSelected.zone.name " 
      style="overflow-y: scroll"
    >
      <template v-slot:header-actions>
        <v-btn v-if="openListReservation" text small class="ml-3" @click="openReservationForm"
        >
          <v-icon left>mdi-plus</v-icon>
          Aggiungi
        </v-btn>
        <v-btn v-if="openDetailReservation" text small class="ml-3" @click="closeReservationForm"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          Indietro
        </v-btn>
      </template>
      <ReservationList
          v-if="openListReservation"
          :reservations="reservations"
          @edit-reservation="openEditReservation"
          @delete-reservation="deleteReservation"
      >

      </ReservationList>
      <ReservationForm
        v-if="openDetailReservation"
        :bus="bus"
        :valid.sync="canSave"
        :table-selected="tableSelected"
        :reservation-day="reservationDay"
        :reservation-selected="reservationSelected"
        @saved-reservation="handleSaveReservation"
        @updated-reservation="handleUpdateReservation"
        @close="close"
      >

      </ReservationForm> 
      <template v-slot:footer-actions>
        <v-btn text color="error" @click="close">Annulla</v-btn>
        <v-btn v-if="openDetailReservation" text :disabled="!canSave" @click="saveReservation">Salva</v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog.vue";
import ErrorDialog from "@/components/common/StandardDialog.vue";
import ReservationList from "@/components/bar/reservation/ReservationList.vue";
import ReservationForm from "@/components/bar/reservation/ReservationForm.vue";

import zoneActiveHandler from "@/components/bar/handlers/zoneActiveHandler.js";
import reservationService from "@/services/bar/reservation.service.js"

import Vue from 'vue'

export default {
  name: "ReservationDialog",
  components: {
    StandardDialog,
    ErrorDialog,
    ReservationList,
    ReservationForm,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    tableCode:{
      type:String,
      default:''
    },
    tableSelected: {
      type: Object,
      default: undefined,
    },
    reservations: {
      type: Array,
      default: () => {
        return new []
      }
    },
    reservationDay: {
      type: Number,
      default: 0,
    },
  },
  data: function() {
    return {
      activeZones: [],
      zoneSelected: [],
      localOpenDialog: false,
      showSingleZone: false,
      openDetailReservation: false,
      openListReservation: false,
      zoneSeletedFromTable: undefined,
      reservationSelected: undefined,
      canSave: false,
      bus: new Vue(),
    }
  },
  computed: {
  },
  mounted() { 
    this.localOpenDialog = this.openDialog;
    this.openListReservation = true;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    openReservationForm() {
      this.openDetailReservation = true;
      this.openListReservation = false;
    },
    closeReservationForm() {
      this.openDetailReservation = false;
      this.openListReservation = true;
    },
    saveReservation() {
      this.bus.$emit("save-reservation")
    },
    handleSaveReservation() {
      this.$emit("saved-reservation");
    },
    handleUpdateReservation() {
      this.$emit("updated-reservation");
    },
    openEditReservation(reservationId) {
      reservationService.get(reservationId).then((reservation) => {
        this.reservationSelected = reservation;
        this.openReservationForm();
      })
    },
    deleteReservation(reservationId, tableId) {
      reservationService.delete({id: reservationId}).then(() => {
        zoneActiveHandler.loadReservations({
          tableId: tableId,
          reservationDate: new Date()
        });
      })
    }
  },
}
</script>