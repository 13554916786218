<template>
  <div>
    <StandardDialog
      v-model="localOpenDialog"
      title="Lista Clienti Occasionali Odierni"
    >
      <v-row>
        <v-col cols="4">
          <v-card
            min-height="73dvh"
            max-height="73dvh"
            height="73dvh"
            color="white"
            class="mt-2"
            style="overflow-y: auto;"
          >
            <v-card-text class="transparent">
              <div style="font-size: 15px; font-weight: bold">
                Clienti Occasionali dal
                <DateTimeFormattor
                  v-model="oneWeekAgoReservation"
                  format="dd/mm/yyyy"
                ></DateTimeFormattor>
                al
                <DateTimeFormattor
                  v-model="todayReservation"
                  format="dd/mm/yyyy"
                ></DateTimeFormattor>
              </div>
              <v-list class="transparent">
                <v-divider></v-divider>
                <v-list-item
                  class="ma-2"
                  v-for="(occasionalCustomer, id) in occasionalCustomers"
                  :key="id"
                >
                  <v-list-item-content>
                    <v-list-item-title 
                      :style="{
                        'background-color': !!occasionalCustomer.selected ? '#e6ffb3' : 'white'
                      }">
                      <DateTimeFormattor
                        :with-time="true"
                        :with-date="true"
                        v-model="occasionalCustomer.createdAt"
                        format="dd/mm/yyyy"
                      ></DateTimeFormattor>
                    </v-list-item-title>
                    <v-divider></v-divider>
                  </v-list-item-content>
                  <v-btn icon @click="handleOpenBill(occasionalCustomer)">
                    <v-icon>mdi-arrow-right-bold-circle</v-icon>
                  </v-btn>
                  <v-btn icon @click="editBill(occasionalCustomer)">
                    <v-icon>mdi-notebook-edit</v-icon>
                  </v-btn>
                </v-list-item>
                <v-divider></v-divider>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card
            min-height="73dvh"
            height="73dvh"
            class="mt-2"
            color=" light-green lighten-5"
          >
            <ListItemComponent
              :flat="true"
              class="ma-2"
              title="Prodotti Ordinati"
              color=" light-green lighten-5"
              :items="localSelectedBillEntities"
              :item-description="
                (item) =>
                  !!item.serviceId ? item.service.name : item.item.description
              "
              :item-price="
                (item) =>
                  !!item.serviceId ? item.service.price : item.item.priceSelling
              "
              :item-quantity="(item) => item.quantity"
              min-height="69vh"
              max-height="62vh"
              :allPay="true"
            >
            </ListItemComponent>
          </v-card>
        </v-col>
        <v-col cols="4">
          <BarPayment 
            class="ma-2" 
            :bus="dailyBus"
          >
          </BarPayment>
        </v-col>
      </v-row>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="close">Chiudi</v-btn>
      </template>
    </StandardDialog>
    <MenuDialog
      :billId="localSelectedBill.id"
      :openMenu="openMenuDialog"
      :hide-close-table="true"
      :show-close-bill="true"
      @close="closeMenuDialog"
    ></MenuDialog>
  </div>
</template>


<script>
import CurrentReservation from "@/components/bar/daily-reservation/CurrentReservation.vue";
import DailyReservationList from "@/components/bar/daily-reservation/DailyReservationList.vue";
import BarPayment from "@/components/bar/daily-reservation/BarPayment.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import billService from "@/services/bills/bills.service";
import DateTimeFormattor from "@/components/common/DateTimeFormattor";
import Vue from "vue";
import ListItemComponent from "@/components/common/ListItemComponent.vue";
import MenuDialog from "@/components/bar/daily-reservation/MenuDialog.vue";

export default {
  components: {
    BarPayment,
    CurrentReservation,
    DailyReservationList,
    StandardDialog,
    DateTimeFormattor,
    ListItemComponent,
    MenuDialog,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localSelectedBill: {},
      localOpenDialog: false,
      todayReservation: undefined,
      oneWeekAgoReservation: undefined,
      occasionalCustomers: undefined,
      localSelectedBillEntities: [],
      openMenuDialog: false,
      dailyBus: new Vue(),
    };
  },
  mounted() {
    this.todayReservation = new Date();
    this.oneWeekAgoReservation = new Date(this.todayReservation.getTime() - 7 * 24 * 60 * 60 * 1000)
    this.fetchOccasionalCustomers();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async fetchOccasionalCustomers() {
      try {
        this.occasionalCustomers = await billService.listOccasionalCustomers();
      } catch (error) {
        console.log(error);
      }
    },
    handleOpenBill(occasionalCustomer, ctrl = false) {
      const paidbill = occasionalCustomer.billEntities.filter(elem=>{
        return elem.paidQuantity > 0
      })
      if(paidbill.length == 0){
        occasionalCustomer.selected = true
        if(!!this.localSelectedBill.selected) this.localSelectedBill.selected = false
        this.localSelectedBill = occasionalCustomer;
        this.localSelectedBillEntities = occasionalCustomer.billEntities;
        this.dailyBus.$emit("reload-bill", occasionalCustomer);
        this.dailyBus.$emit("reload-items", occasionalCustomer.billEntities);
      }
      else if (!ctrl)
        this.editBill(occasionalCustomer)
    },
    addEntity() {},
    removeEntity() {},
    deleteEntity() {},
    async editBill(occasionalCustomer) {
      try {
        this.localSelectedBill = occasionalCustomer;
        this.localSelectedBillEntities = occasionalCustomer.billEntities;
        this.dailyBus.$emit("reload-bill", occasionalCustomer);
        this.dailyBus.$emit("reload-items", occasionalCustomer.billEntities);

        occasionalCustomer.selected = true
        if(!!this.localSelectedBill.selected) this.localSelectedBill.selected = false
        this.localSelectedBill = occasionalCustomer;
        this.openMenuDialog = true
      } catch (error) {
        console.log(error);
      }
    },
    async closeMenuDialog(){
      this.openMenuDialog = false
      await this.fetchOccasionalCustomers()
      const occasionalCustomer = this.occasionalCustomers.find(element => element.id == this.localSelectedBill.id)
      if (!!occasionalCustomer) {
        this.handleOpenBill(occasionalCustomer, true)
      } else {
        this.localSelectedBill = {}
        this.localSelectedBillEntities = []
      }      
    }
  },
  watch: {
    openDialog(newVal) {
      if (!!newVal) this.fetchOccasionalCustomers();
      this.localOpenDialog = newVal;
    },
  },
};
</script>
