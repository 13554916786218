<template>
  <div>
    <v-subheader class="font-italic font-weight-light">
      Elenco Menu
    </v-subheader>

    <MenuCardList
      :items="menuList"
      @input="selectedMenu"
      :returnObject="true"
      card-max-height="100"
      card-min-height="100"
      card-max-width="100"
      card-min-width="100"
    >
      <template v-slot:item="{ item, cardMinHeight }">
        <div
          class="pa-2 d-flex flex-column justify-center align-center"
          :style="{
            'min-height':
              cardMinHeight.indexOf('px') != -1
                ? cardMinHeight
                : cardMinHeight + 'px',
            'background-color': item.color,
          }"
        >
          <div class="mt-2">{{ item.name }}</div>

          <v-btn v-if="localDeleteMenu" icon class="remove">
            <v-icon @click="remove(item)">mdi-close</v-icon>
          </v-btn>
        </div>
      </template>
    </MenuCardList>

    <MenuUpdateDialog
      v-if="openDetailMenu"
      :open-dialog="openDetailMenu"
      :menu-id="menuIdSelected"
      @close="close"
      @updated-menu="reloadMenusList"
    >
    </MenuUpdateDialog>
  </div>
</template>

<script>
import MenuCardList from "@/components/common/ResponsiveCardSelector.vue";
import MenuUpdateDialog from "@/components/bar/settings/menu/edit/MenuUpdateDialog.vue";
import menuManagement from "@/services/bar/menu.service.js";
import Vue from "vue";

export default {
  components: {
    MenuCardList,
    MenuUpdateDialog,
  },
  props: {
    deleteMenu: {
      type: Boolean,
      default: false,
    },
    disableMenu: {
      type: Boolean,
      default: false,
    },
    menuCounter: {
      type: Number,
      default: 0,
    },
    bus: {
      type: Object,
      default: () => {
        return new Vue();
      },
    },
  },
  data: function () {
    return {
      menuList: [],
      localMenuCounter: 0,
      openDetailMenu: false,
      loading: false,
    };
  },
  mounted() {
    this.localMenuCounter = this.menuCounter;
    this.loadMenus();
    this.bus.$on("reload-menus", this.reloadMenusList);

    this.bus.$on("remove-last-menu", () => {
      this.remove(this.menuList.at(-1));
    });
  },
  methods: {
    close() {
      this.openDetailMenu = false;
    },
    reloadMenusList() {
      this.close();
      this.loadMenus();
    },
    loadMenus() {
      menuManagement.list().then((data) => {
        this.menuList = data;
        this.localMenuCounter = this.menuList.length;
        this.$emit("update:menu-counter", this.localMenuCounter);
      });
    },
    selectedMenu(menu) {
      if(!!this.loading)
        return;

      this.openDetailMenu = true;
      if (menu) this.menuIdSelected = Number(menu.id);
    },
    remove(menu) {
      this.loading = true;
      let confirmed = confirm('Sei sicuro di voler eliminare il menu ' + menu.name + '?')
      if (!confirmed) {
        this.loading = false;
        return;
      }
      
      menuManagement.delete(menu).then(() => {
        this.reloadMenusList();
        this.loading = false;
      });
    },
  },
  computed: {
    localDeleteMenu() {
      return this.deleteMenu;
    },
  },
};
</script>

<style>
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}
.disable {
  position: absolute;
  left: 2px;
  top: 0;
  cursor: pointer;
}
</style>