<template>
  <div>
<!--     <v-btn icon @click="onChangeView('daily-review')">
      <v-badge
        bordered
        :dot="!reservationNumberTodayToPay"
        :value="reservationNumberTodayToPay"
        :content="reservationNumberTodayToPay"
      >
        <v-icon>mdi-book-multiple</v-icon>
      </v-badge>
    </v-btn> -->
    <v-btn icon @click="onChangeView('')">
      <v-icon>mdi-home</v-icon>
    </v-btn> 
    <v-btn icon @click="onChangeView('calendar')">
      <v-badge
        bordered
        :dot="!reservationNumberToday"
        :value="reservationNumberToday"
        :content="reservationNumberToday"
      >
        <v-icon>mdi-calendar</v-icon>
      </v-badge>
    </v-btn>
   <v-btn icon @click="onChangeView('mapzones')">
      <v-icon>mdi-table-picnic</v-icon>
    </v-btn>
    <v-btn icon @click="onChangeView('settings')">
      <v-icon>mdi-cog</v-icon>
    </v-btn>
  </div>
</template>

<script>
import reservationService from "@/services/bar/reservation.service";
import Vue from 'vue'
export default {
  props: {
    currentView: {
      type: String,
      default: "",
    },
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
  },
  mounted() {
    this.countReservation();
    this.bus.$on("reload-reservations", this.countReservation);
  },
  data() {
    return {
      localCurrentView: this.currentView,
      reservationNumberToday: 0,
      reservationNumberTodayToPay: 0,
    };
  },
  methods: {
    onChangeView(section) {
      this.localCurrentView = section;
      this.$emit("on-change", section);
    },
    countReservation() {
      this.countReservationToPay();
      this.countReservationCalendar();
    },
    countReservationCalendar() {
      reservationService
        .list({ todayReservation: true, statusOrNull:'opened'})
        .then((reservations) => {
          this.reservationNumberToday = reservations.length;
        });
    },
    countReservationToPay() {
      reservationService
        .list({ todayReservation: true, billStatus: "opened" })
        .then((reservations) => {
          this.reservationNumberTodayToPay = reservations.length;
        });
    },
  },
  watch: {
    reservationNumberToday(newVal) {
      this.reservationNumberToday = newVal;
    },
  },
};
</script>

<style>
</style>