import eventsHandler from '@/services/common/eventsHandler.js'

class paymentTermForm extends eventsHandler {
  constructor() {
    super()
    this._paymentTerm = {}
    this._valid = false
  }

  get paymentTerm() {
    const paymentTerm = {
      ...this._paymentTerm
    }
    return paymentTerm
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._paymentTerm.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetPaymentTerm() {
    this._paymentTerm = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {paymentTerm: {}})
  }

  updateField(key, value) {
    this._paymentTerm[key] = value
    this._executeCallbacksOf('update', {paymentTerm: this._paymentTerm})
    this._executeCallbacksOf('update-' + key, {key: this._paymentTerm[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(paymentTerm) {
    this._paymentTerm = paymentTerm
    this._executeCallbacksOf('update', {paymentTerm: this._paymentTerm})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new paymentTermForm()