<template>
  <div v-if="activeZones.length > 0">
    <div>
      <div class="d-flex flex-wrap">
        <v-btn
          class="mt-3 ml-2"
          text
          @click="newOccasionalCustomer"
          :disabled="disabled"
        >
          Nuovo Cliente Occasionale
        </v-btn>
        <v-btn
          class="ml-2 mt-3"
          text
          @click="listOccasionalCustomers"
          :disabled="disabled"
        >
          Lista Clienti Occasionali
        </v-btn>
      </div>
      <v-row class="ma-0 pa-0">
        <v-col 
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="2"
          class="ma-0 pa-0 px-2"
        >
          <div class="pa-3 d-flex justify-center align-center">
            <ResponsiveCardSelector
              :items="activeZones"
              v-model="zoneSelected"
              :returnObject="true"
              item-key="key"
              card-max-height="40"
              card-min-height="40"
              card-max-width="120"
              card-min-width="120"
              :apply-d-flex="false"
              :multiple="false"
              :mandatory="true"
            >
              <template v-slot:item="{ item, cardMinHeight }">
                <div
                  class="d-flex flex-column justify-center align-center"
                  :style="{
                    'min-height':
                      cardMinHeight.indexOf('px') != -1
                        ? cardMinHeight
                        : cardMinHeight + 'px',
                    'background-color': item.zone.color,
                  }"
                >
                  <div class="font-weight-medium">
                    {{ item.zone.name }}
                  </div>
                </div>
              </template>
            </ResponsiveCardSelector>
          </div>
        </v-col>

        <v-col 
          class="ma-0 pa-0 pt-4"
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="10"
        >
          <ZoneMap
            :zone-selected="zoneSelected"
            @handle-selection-element="handleSelectionElement"
            :bus="zoneMapBus"
            :disabled="disabled"
          ></ZoneMap>
        </v-col>
      </v-row><!-- 
      <v-row>
        <v-col >
        </v-col>
        <v-col>
          <v-btn @click="joinTables" disabled>
            Unisci
          </v-btn>    
        </v-col>
        <v-col>
          <v-btn @click="splitTables" disabled>
            Dividi
          </v-btn>
        </v-col>
      </v-row> -->
    </div>

    <MenuDialog
      :billId="!!currentTableSelected && !!currentTableSelected.currentBillId ? Number(currentTableSelected.currentBillId) : occasionalCustomerBillId"
      :selectedTable="currentTableSelected"
      :openMenu="openMenuDialog"
      :hide-close-table="!currentTableSelected"
      @close="openMenuDialog = false"
      @reload="fetchZones"
    ></MenuDialog>

    <OccasionalCustomersListDialog
      :openDialog="openOccasionalCustomerListDialog"
      @close="closeDialog"
    ></OccasionalCustomersListDialog>

    <StandardDialog
      v-model="openBillDialog"
      @close="openBillDialog = false"
      dialog-width="50vw"
      :dialog-height="null"
      persistent
      :title="!!currentTableSelected && !!currentTableSelected.i ? 'Apri Comanda Tavolo '+ currentTableSelected.i : '' "
    >
      <v-alert v-if="!!currentTableSelected && !!currentTableSelected.reservations" dense>
        <div class="font-italic font-weight-light mt-2">Lista Prenotazioni Odierne</div>
      </v-alert>
      <TypeDataTable
        :show-select="false"
        item-key="id"
        fixed-header
        :show-actions="false"
        :headers="headersItems"
        :disable-pagination="true"
        :items="currentTableSelected.reservations"
        v-if="!!currentTableSelected && !!currentTableSelected.reservations"
      >
      </TypeDataTable>

    <template v-slot:footer-actions>
      <v-btn
        text
        @click="openBillDialog = false"
        color="error"
      >
        Annulla
      </v-btn>
      <v-btn
        text
        @click="addBillToTable"
        color="deafult"
      >
        Apri
      </v-btn>
    </template>
    </StandardDialog>

  </div>
  <div v-else class="d-flex justify-center align-center">
    Nessuna zona attiva oggi
  </div>
</template>

<script>
import StandardDialog from "@/components/common/StandardDialog";
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";
import MenuDialog from "@/components/bar/daily-reservation/MenuDialog.vue";

import zoneActiveHandler from "@/components/bar/handlers/zoneActiveHandler.js";
import tableService from "@/services/bar/table.service";
import billService from "@/services/bills/bills.service";

import ReservationCreateDialog from "@/components/bar/reservation/ReservationCreateDialog";
import ZoneMap from "@/components/bar/reservation/detail-zone/ZoneMap.vue";
import TypeDataTable from "@/components/common/TypeDataTable.vue";
import OccasionalCustomersListDialog from "@/components/bar/daily-reservation/OccasionalCustomersListDialog.vue"
import Vue from "vue"

export default {
  name: "DailyZonesDialog",
  components: {
    DateTimeFormattor,
    StandardDialog,
    ResponsiveCardSelector,
    ZoneMap,
    MenuDialog,
    ReservationCreateDialog,
    TypeDataTable,
    OccasionalCustomersListDialog,
  },
  data() {
    return {
      today: new Date().toISOString().substring(0,10),
      reservations: undefined,
      localOpenDailyZones: this.openDailyZones,
      reservationSelected: undefined,
      currentTableSelected: undefined,
      openReservationSelectDialog: false,
      activeZones: [],
      zoneSelected: undefined,
      weekEnablings: undefined,
      titleReservationSelectDialog: "",
      openMenuDialog: false,
      menuDialogCurrentReservation: { id: undefined },
      openBillDialog: false,
      localProvidedItems: undefined,
      openOccasionalCustomerListDialog: undefined,
      occasionalCustomerBillId: undefined,
      joinTableMod: false,
      headersItems: [
        { text: "Nome Prenotazione", value: "reservationName" },
        { text: "Orario", value: "startDate", type: "time" },
        { text: "Numero Di Telefono", value: "reservationPhone" },
      ],
      zoneMapBus: new Vue(),
    };
  },
  props: {
    openDailyZones: {
      type: Boolean,
      default: false,
    },
    bus: {
      type: Object,
      default: () => { new Vue() }
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.fetchZones();
    zoneActiveHandler.on("reload-reservations", ({ reservations }) => {
      this.reservations = reservations;
    });
    this.bus.$on("reload-tables", () => this.fetchZones())
  },
  methods: {
    statusClosed(reservation) {
      if (!!reservation.billReservation)
        return reservation.billReservation.bill.status == "closed";
    },
    fetchZones() {
      this.weekEnablings = new Date().getDay();
      zoneActiveHandler
        .loadActiveZones(this.weekEnablings)
        .then((activeZones) => {
          this.activeZones = activeZones;
          if(!this.zoneSelected)
            this.zoneSelected = activeZones[0];
          else
            for (const activeZone of activeZones){
              if(this.zoneSelected.key == activeZone.key)
                this.zoneSelected = activeZone
            } 
        });
    },
    handleSelectionElement(table) {
      if(this.disabled)
        return;
      
      if(!!this.joinTableMod)
        return;
      this.currentTableSelected = table;
      if (!!table && !table.currentBillId) {
        this.openBillDialog = true
      } else if (!!table && !!table.currentBillId) {
        this.openMenuDialog = true
      }
    },
    changeCurrentReservation(currentReservation) {
      let tableWithReservation = {
        id: this.currentTableSelected.id,
        currentReservationId: currentReservation.id,
      };

      tableService.update(tableWithReservation).then((value) => {
        this.openReservationSelectDialog = false;
      });

      this.reservationSelected = currentReservation;
      this.$emit("change-reservation", currentReservation);
      this.openReservationSelectDialog = false;
    },
    async addBillToTable(){
      this.openBillDialog = false
      try {
        let billCreated = await billService.createBillFromRestaurant(this.currentTableSelected)
        this.currentTableSelected.status = billCreated.status
        this.currentTableSelected.currentBillId = Number(billCreated.id)
        this.fetchZones()
        this.openMenuDialog = true
      } catch (error) {
        console.log(error)
      }
    },
    async newOccasionalCustomer(){
      try {
        let billCreated = await billService.createBillFromRestaurant(undefined,undefined,true)
        this.occasionalCustomerBillId = Number(billCreated.id)
        this.currentTableSelected = undefined
        this.openMenuDialog = true
      } catch (error) {
        console.log(error)
      }
    },
    async listOccasionalCustomers(){
      try {
        this.openOccasionalCustomerListDialog = true
      } catch (error) {
        console.log(error)
      }
    },
    closeDialog(){
       this.openOccasionalCustomerListDialog = false
    },
    joinTables(){
      this.joinTableMod = !this.joinTableMod
      this.zoneMapBus.$emit("join-tables")
    },
    splitTables(){
      this.zoneMapBus.$emit("split-tables")
    },
  },
  watch: {
    zoneSelected(newVal) {
      this.zoneSelected = newVal;
    },
  },
};
</script>
<style scoped>
  .sub_div {
      position: absolute;
      bottom: 1vh;
  }
</style>