<template>
  <div>
    <v-card
      :max-width="maxWidht"
      :min-width="minWidht"
      :max-height="maxHeight"
      :min-height="minHeight"
    >
      <v-card-text v-if="currentReservation">
        <div style="font-size: 15px; font-weight: bold">
          Prenotazione Selezionata
        </div>
        <v-divider></v-divider>
        <v-list class="transparent">
          <v-list-item>
            <v-icon :color="currentReservation.tableColor" class="mr-3"
              >mdi-account-group</v-icon
            >
            <v-list-item-title class="">
              {{ currentReservation.reservationName }},
              {{ currentReservation.guests }} ospiti
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="">
            <v-icon :color="currentReservation.zoneColor" class="mr-3"
              >mdi-calendar</v-icon
            >
            <v-list-item-subtitle class="">
              <DateTimeFormattor
                :with-time="true"
                v-model="currentReservation.startDate"
                not-specified-text="Non specificato"
              ></DateTimeFormattor>
            </v-list-item-subtitle>
          </v-list-item>
          <v-list-item>
            <v-icon :color="currentReservation.tableColor" class="mr-3"
              >mdi-table-picnic</v-icon
            >
            <v-list-item-title>
              Tavolo {{ currentReservation.tableCode }}</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-icon :color="currentReservation.zoneColor" class="mr-3"
              >mdi-select-group</v-icon
            >
            <v-list-item-subtitle class="">
              in {{ currentReservation.zoneName }}
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card-text>
      <div v-else>loading...</div>
    </v-card>
  </div>
</template>

<script>
import reservationService from "@/services/bar/reservation.service/";
import DateTimeFormattor from "@/components/common/DateTimeFormattor";
export default {
  components: {
    DateTimeFormattor,
  },
  props: {
    currentReservationId: {
      type: Number,
      default: 0,
    },
    maxWidht: {
      type: String,
      default: "",
    },
    maxHeight: {
      type: String,
      default: "40vh",
    },
    minWidht: {
      type: String,
      default: "",
    },
    minHeight: {
      type: String,
      default: "40vh",
    },
  },
  data() {
    return {
      currentReservation: undefined,
    };
  },
  mounted() {
    this.fetchReservation(this.currentReservationId);
  },
  methods: {
    fetchReservation(id) {
      reservationService
        .get(id)
        .then((reservation) => {
          this.currentReservation = {
            tableCode: reservation.table.code,
            tableColor: reservation.table.color,
            zoneName: reservation.zone.name,
            zoneColor: reservation.zone.color,
            reservationName: reservation.reservationName,
            guests: reservation.guests,
            startDate: reservation.startDate,
            endDate: reservation.endDate,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    currentReservationId(newVal) {
      this.fetchReservation(newVal);
    },
  },
};
</script>

<style>
</style>