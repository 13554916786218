<template>
  <div>
    <ResponsiveCardSelector
      v-if="!showSingleZone"
      @input="changeDay"
      :items="week"
      v-model="weekEnablings"
      item-key="key"
      :multiple="false"
      :card-max-height="expanded ? '100' : '10'"
      :card-min-height="expanded ? '100' : '10'"
      :card-max-width="expanded ? '100' : '50'"
      :card-min-width="expanded ? '100' : '50'"
      class="pa-2 d-flex justify-center align-center"
    >
      <template v-slot:item="{ item, cardMinHeight }">
        <div
          class="pa-2 d-flex flex-column justify-center align-center"
          :style="{
            'min-height':
              cardMinHeight.indexOf('px') != -1
                ? cardMinHeight
                : cardMinHeight + 'px',
          }"
        >
          <div
            id="weekDayName"
            :style="{ 'font-size': expanded ? '25px' : '15px' }"
          >
            {{ item.name }}
          </div>
        </div>
      </template>
    </ResponsiveCardSelector>
    <div v-if="activeZones.length > 0">
      <div v-if="showSingleZone">
        <div>
          <div class="mt-1">
            <v-btn @click="showSingleZone = false" icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
        </div>
        <v-row>
          <v-col cols="3">
            <v-card
              max-width="200"
              height="50"
              class="
                d-flex
                mt-4
                flex-wrap
                justify-center
                align-center
                mt-3
                mb-6
                ml-2
              "
              :color="zoneSelected.zone.color"
            >
              <div
                width="100%"
                class="d-flex justify-end font-italic font-weight-normal"
                style="font-size: 30px"
              >
                {{ zoneSelected.zone.name }}
              </div>
            </v-card>
            <LeftSideMenuBar
              v-model="selectedItem"
              :items="filteredMenuItems"
              identifier-key="name"
              @item-click="handleMenuItemsClick"
            ></LeftSideMenuBar>
          </v-col>
          <v-col cols="9">
            <component
              :is="currentComponent"
              v-bind="currentProps"
              @handle-selection-element="handleSelectionElement"
            >
            </component>
          </v-col>
        </v-row>
      </div>
      <div v-else class="mt-3 d-flex flex-wrap justify-center align-center">
        <div
          v-for="zone in activeZones"
          :key="zone.key"
          class="half-width half-height ml-2"
        >
          <div
            class="font-italic font-weight-normal mb-2"
            style="font-size: large"
          >
            {{ zone.zone.name }}
          </div>
          <GridLayoutComponent
            :background-color="zone.zone.color"
            :elements="zone.tables"
            :margin-element="[10, 10]"
            :element-height="10"
          >
            <template v-slot:item-content="el">
              <div
                @click="handleSelectionElement(el)"
                class="text d-flex justify-center align-center"
              >
                {{ el.i }}
              </div>
            </template>
          </GridLayoutComponent>
          <v-btn
            @click="openSingleZone(zone)"
            text
            style="float: right"
            class="mt-2"
          >
            <div
              class="font-italic font-weight-normal"
              style="font-size: large"
            >
              Apri
            </div>
          </v-btn>
        </div>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center">
      Nessuna zona attiva oggi
    </div>

    <ReservationDialog
      v-if="openDetailReservation"
      :reservations="reservations"
      :open-dialog="openDetailReservation"
      :table-selected="tableSelected"
      :table-code="tableSelected.i"
      :reservation-day="weekEnablings"
      @saved-reservation="handleSaveReservation"
      @updated-reservation="handleUpdateReservation"
      @close="close"
    >
    </ReservationDialog>
  </div>
</template>

<script>
import GridLayoutComponent from "@/components/common/GridLayoutComponent.vue";
import ReservationDialog from "@/components/bar/reservation/ReservationDialog.vue";
import ReservationList from "@/components/bar/reservation/ReservationList.vue";
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector.vue";
import ReservationListByZone from "./detail-zone/ReservationListByZone.vue";

import zoneService from "@/services/bar/zone.service";
import tableUtils from "@/mixins/bar/tableUtils";
import zoneActiveHandler from "@/components/bar/handlers/zoneActiveHandler.js";
import LeftSideMenuBar from "@/components/common/LeftSideMenu.vue";
import ZoneMap from "./detail-zone/ZoneMap.vue";
export default {
  name: "BarReservations",
  mixins: [tableUtils],
  components: {
    GridLayoutComponent,
    ReservationDialog,
    ReservationList,
    ResponsiveCardSelector,
    LeftSideMenuBar,
    ReservationListByZone,
    ZoneMap,
  },
  props: {
    cardMinHeight: {
      type: String,
      default: "100",
    },
    cardMaxHeight: {
      type: String,
      default: "100",
    },
    cardMinWidth: {
      type: String,
      default: "100",
    },
    cardMaxWidth: {
      type: String,
      default: "100",
    },
    weekTextDimension: {
      type: String,
      default: "25",
    },
  },
  data() {
    return {
      currentComponent: undefined,
      components: [],
      activeZones: [],
      zoneSelected: [],
      reservations: [],
      tableReservations: [],
      showSingleZone: false,
      openDetailReservation: false,
      tableSelected: undefined,
      zoneSeletedFromTable: undefined,
      reservationSelected: undefined,
      openListReservation: true,
      weekEnablings: 0,
      week: [
        { key: 0, name: "Dom" },
        { key: 1, name: "Lun" },
        { key: 2, name: "Mar" },
        { key: 3, name: "Mer" },
        { key: 4, name: "Gio" },
        { key: 5, name: "Ven" },
        { key: 6, name: "Sab" },
      ],
      selectedItem: {},
      menuItems: [
        {
          title: "Mappa",
          name: "zoneMap",
          icon: "mdi-palette",
          component: "ZoneMap",
        },
        {
          title: "Lista Prenotazioni",
          name: "reservationList",
          icon: "mdi-clipboard-text",
          component: "ReservationListByZone",
        },
        {
          title: "Calendario",
          name: "reservationCalendar",
          icon: "mdi-calendar",
          // component: "ReservationCalendar",
        },
      ],
    };
  },
  props: {
    expanded: {
      trype: Boolean,
      default: true,
    },
  },
  mounted() {
    this.weekEnablings = new Date().getDay();
    zoneActiveHandler
      .loadActiveZones(this.weekEnablings)
      .then((activeZones) => {
        this.activeZones = activeZones;
        zoneActiveHandler.on("reload-active-zones", ({ activeZones }) => {
          this.activeZones = activeZones;
        });
      });
  },
  computed: {
    filteredMenuItems() {
      let menuItems = [...this.menuItems];
      return menuItems;
    },
    currentProps() {
      if (this.currentComponent === "ZoneMap") {
        return { zoneSelected: this.zoneSelected };
      } else if (this.currentComponent === "ReservationListByZone") {
        return {
          zoneId: Number(this.zoneSelected.zone.id),
          tablesIdsListByZone: this.zoneSelected.tables.map((item) => item.id),
        };
      }
    },
  },
  methods: {
    handleMenuItemsClick(item) {
      this.selectedItem = item;
    },
    changeDay() {
      zoneActiveHandler.loadActiveZones(this.weekEnablings);
    },
    close() {
      this.openDetailReservation = false;
      this.reservations = [];
    },
    handleSelectionElement(table) {
      this.openDetailReservation = true;
      if (table) {
        this.tableSelected = table;

        zoneActiveHandler
          .loadReservations({
            tableId: table.id,
            reservationDate: this.setDailyDate(),
          })
          .then((reservations) => {
            // TODO for reload reservations
            this.reservations = reservations;
            zoneActiveHandler.on("reload-reservations", ({ reservations }) => {
              this.reservations = reservations;
            });
          });
      }
    },
    openSingleZone(zone) {
      this.showSingleZone = true;
      this.zoneSelected = zone;

      this.selectedItem = this.menuItems.filter(
        (item) => item.name === "zoneMap"
      )[0];
    },
    setDailyDate() {
      let date = new Date();
      date.setDate(this.weekEnablings);
      return date;
    },
    handleSaveReservation() {
      this.openDetailReservation = false;
      zoneActiveHandler.loadReservations();
      this.reservationSelected = undefined;
      this.tableReservations = [];
    },
    handleUpdateReservation() {
      this.openDetailReservation = false;
      zoneActiveHandler.loadReservations();
      this.reservationSelected = undefined;
      this.tableReservations = [];
    },
  },
  watch: {
    selectedItem(newVal) {
      this.currentComponent = newVal.component;
      this.currentProps;
    },
  },
};
</script>

<style lang="scss" scoped>
.zone-scaled {
  transform: scale(0.8) translate(-10%, -15%);
}
</style>