<template>
  <div>
    <div class="mt-3 d-flex full-width">
      <div class=" pa-1 full-width d-flex"  v-if="expanded==''">
        <div class="pa-1 half-width">
          <v-card 
            :style="{ 'overflow-y':  'scroll' }"
            style="max-height: 100%; height: 100%;"
          >
            <v-card-title class="pa-0 flex-grow-0 header">
              <v-toolbar flat>
                <div class="font-italic font-weight-light">Agenda ristorante</div>
                <v-spacer></v-spacer>
              </v-toolbar>
            </v-card-title>
            <v-card-text class="pa-0 mt-1 flex-auto body" >
              <ReservationsCalendar
                v-if="!tableView"
                :class="{
                  'section-fade-in': expanded == 'calendar',
                }"
                @reload-events="handleRecalcBadge"
                @tableView="tableView = true"
                :disabled="isLicenseBlocked"
              ></ReservationsCalendar>
              <TablesCalendar
                v-else-if="!!tableView"
                @tableView="tableView = false"
                @change-in-reservations="handleRecalcBadge"
              ></TablesCalendar>
            </v-card-text>
          </v-card>
        </div>
        <div class="pa-1 half-width ">
          <v-card 
            :style="{ 'overflow-y': expanded == '' ? 'scroll' : 'scroll' }"
            style="max-height: 100%; height: 100%;"
          >
            <v-card-title 
              :style=" {
                height: $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '100px' : '56px'
              }"
              class="pa-0 flex-grow-0 header"
            >
              <v-toolbar flat>
                <div class="font-italic font-weight-light">Mappa Delle Sale</div>
                <v-spacer></v-spacer>
                <slot name="header-actions">
                  <BarHeaderActions
                    :current-view="expanded"
                    @on-change="handleChangeView"
                    :bus="bus"
                  ></BarHeaderActions>
                </slot>
              </v-toolbar>
            </v-card-title>
            <v-card-text class="pa-3 mt-1 flex-auto body">
              <slot name="content">
                <DailyZonesList
                  :bus="busDailyZoneList"
                  :disabled="isLicenseBlocked"
                ></DailyZonesList>  
              </slot>
            </v-card-text>
          </v-card>
        </div>
      </div>  
      <div class="pa-1 full-width" v-else-if="expanded != ''">
        <v-card 
            :style="{ 'overflow-y':  'scroll' }"
            style="max-height: 100%; height: 100%;"
          >
            <v-card-title class="pa-0 flex-grow-0 header">
              <v-toolbar flat>
                <div class="font-italic font-weight-light" v-if="expanded=='calendar'">Agenda ristorante</div>
                <div class="font-italic font-weight-light" v-else-if="expanded=='mapzones'">Mappa Delle Sale</div>
                <div class="font-italic font-weight-light" v-else-if="expanded=='settings'">Impostazioni</div>
                <div class="font-italic font-weight-light" v-else-if="expanded=='daily-review'">Riepilogo del giorno</div>
                <v-spacer></v-spacer>
                <slot name="header-actions">
                  <BarHeaderActions
                    :current-view="expanded"
                    @on-change="handleChangeView"
                    :bus="bus"
                  ></BarHeaderActions>
                </slot>
              </v-toolbar>
            </v-card-title>
            <v-card-text class="pa-0 mt-1 flex-auto body">
              <slot name="content">
                <ReservationsCalendar
                  v-if="expanded == 'calendar'"
                ></ReservationsCalendar>
                <DailyZonesList
                  v-else-if="expanded == 'mapzones'"
                ></DailyZonesList>
                <BarSetting
                  v-else-if="expanded == 'settings'"
                ></BarSetting>
                <DailyReservationHome
                  v-else-if="expanded == 'daily-review'"
                ></DailyReservationHome>
              </slot>
            </v-card-text>
          </v-card>
      </div>
    </div>  
  </div>
</template>

<script>
import BarReservations from "@/components/bar/reservation/BarReservations.vue";
import ZoneManagement from "@/components/bar/settings/zone/ZoneManagement.vue";
import BarSetting from "@/components/bar/settings/BarSettings.vue";
import BarSettingsMinimalized from "@/components/bar/home/BarSettingsMinimalized.vue";
import ReservationsCalendar from "@/components/bar/calendar/ReservationsCalendar.vue";
import BarCalendarMinimalized from "@/components/bar/home/BarCalendarMinimalized.vue";
import DailyReservationHome from "./daily-reservation/DailyReservationHome.vue";
import DailyZonesList from "./daily-reservation/DailyZonesList.vue";
import BarHeaderActions from "@/components/bar/BarHeaderActions";
import TablesCalendar from "@/components/bar/calendar/TablesCalendar.vue";
import Vue from "vue";
export default {
  components: {
    BarHeaderActions,
    BarReservations,
    ZoneManagement,
    BarSetting,
    BarSettingsMinimalized,
    ReservationsCalendar,
    BarCalendarMinimalized,
    DailyReservationHome,
    DailyZonesList,
    TablesCalendar
  },
  data() {
    return {
      expanded: "",
      expandedName: "",
      expandedMinimalizedName: "",
      originalUrl: window.location.pathname,
      defaultUrl: window.location.pathname,
      bus: new Vue(),
      tableView: undefined,
      busDailyZoneList: new Vue(),
      isLicenseBlocked: undefined,
      isOnlyWheelOfFortune: undefined
    };
  },
  mounted: function() {
    window.history.pushState("bar", "bar", this.originalUrl);
  },
  computed: {},
  methods: {
    handleChangeView(nextView) {
      this.expanded = nextView;
    },
    openCalendar() {
      this.openDialog = true;
    },
    expandedChangeHandler(value) {
      this.expandedName = value;
    },
    expandedMinimalizedChangeHandler(value) {
      this.expandedMinimalizedName = value;
    },
    handleRecalcBadge(){
      this.bus.$emit('reload-reservations')
      this.busDailyZoneList.$emit('reload-tables')
    }
  },
  watch: {
    expanded(newVal) {
      try {
        if (newVal !== "") {
          window.history.pushState(
            "section",
            "section",
            this.originalUrl + "?section=" + newVal
          );
        } else {
          window.history.pushState("default", "default", this.defaultUrl);
        }
      } catch (err) {
        console.log("try setting the section name but something goes wrong");
      }
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  },
  isOnlyWheelOfFortune: {
    bind: "isOnlyWheelOfFortune"
  },
};
</script>

<style scoped>
.half-width {
  width: 50%;
}

.half-height {
  height: 100%;
}

.full-width {
  width: 100%;
  height: 98vh;
}

.calendar-section-scaled {
  width: 100%;
  /* transform: scale(0.9) */
}

.bar-section-scaled {
  transform: scale(0.62) translate(3%, -32%);
}
/* 
.section-zones-scaled {
  transform: scale(0.4)
} */

.section-fade-in-calendar {
  animation: smooth 0.5s ease-in;
}
.section-fade-in {
  animation: smooth 0.8s ease-in;
}

.expanded {
  width: 95vw;
  height: 98vh;
}

@keyframes smooth {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>