<template>
  <div>
    <div class="d-flex flex-column justify-center align-center">
      <ResponsiveCardSelector
        :items="zones"
        v-model="selectedZone"
        :returnObject="true"
        item-key="name"
        card-max-height="20"
        card-min-height="20"
        card-max-width="120"
        card-min-width="120"
        :apply-d-flex="false"
        :mandatory="true"
      >
        <template v-slot:item="{ item, cardMinHeight }">
          <div
            class="d-flex flex-column justify-center align-center"
            :style="{
              'min-height':
                cardMinHeight.indexOf('px') != -1
                  ? cardMinHeight
                  : cardMinHeight + 'px',
              'background-color': item.color,
            }"
          >
            <div class="font-weight-medium">
              {{ item.name }}
            </div>
          </div>
        </template>
      </ResponsiveCardSelector>
    </div>

    <div v-for="zone in zones" :key="zone.id">
      <CategoriesCalendar
        :interval-height-default="8"
        :color="zone.color"
        v-if="zone.name == selectedComponent"
        class="section-fade-in"
        style="height: 86vh"
        category-label="Tavoli"
        category-placeholder="Tutti i tavoli"
        category-text-key="code"
        category-sub-text-key="guests"
        category-sub-text="ospiti"
        category-value-key="id"
        :categories-fetcher="fetchZoneTables"
        :canManage="true"
        :bus="bus"
        :event-category="categoryFetcher"
        :event-category-setter="categorySetter"
        event-end="endDate"
        event-start="startDate"
        :event-color="colorFetcher"
        :event-name="nameFetcher"
        :move-between-category="!disabled"
        :move-in-time="!disabled"
        :event-timed="
          (reservation) => {
            return !reservation.allDay;
          }
        "
        :events-fetcher="fetchReservationsForCategories"
        @new-event="handleNewReservation"
        @show-event="handleShowReservation"
        :category-width="100"
        selectMaxWidth="200px"
        v-on:end-drag="handleEndDrag"
      >
        <template v-slot:toolbar-append>       
          <v-btn fab text color="grey darken-2" @click="openSendSmsDialog = true">
            <v-icon >mdi-message-text</v-icon>
          </v-btn>
          <!--<v-btn icon @click="$emit('tableView')">
            <v-icon>mdi-cached</v-icon> 
          </v-btn>-->
        </template>
      </CategoriesCalendar>
    </div>

    <ReservationCreateDialog
      v-if="openReservationDialog"
      :new-reservation="newReservation"
      :reservations="reservations"
      :open-dialog="openReservationDialog"
      :table-selected="tableSelected"
      :reservation-day="weekEnablings"
      @saved-reservation="handleSaveReservation"
      @updated-reservation="handleUpdateReservation"
      @delete-reservation="closeReservation"
      @close="closeReservation"
      @open-menu="openMenuFromReservation"
    >
    </ReservationCreateDialog>

    <MenuDialogCalendar
      :bus="bus"
      v-if="openMenu"
      :open-menu="openMenu"
      @close="closeMenu"
      style="overflow-y: hidden"
      :current-reservation="currentReservation"
    >
    </MenuDialogCalendar>
    
    <ReservationListSendSmsDialog
      :openDialog="openSendSmsDialog"
      :reservations="reservations"
      @close ="openSendSmsDialog = false"
    ></ReservationListSendSmsDialog>  
  </div>
</template>

<script>
import ReservationCreateDialog from "@/components/bar/reservation/ReservationCreateDialog";
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector.vue";
import MenuDialogCalendar from "@/components/bar/calendar/MenuDialogCalendar.vue";
import DailyZonesDialog from "@/components/bar/daily-reservation/DailyZonesDialog.vue";
import Vue from "vue";
import CategoriesCalendar from "@/components/calendar/CategoriesCalendar";
import zoneService from "@/services/bar/zone.service";
import zoneActiveHandler from "@/components/bar/handlers/zoneActiveHandler.js";
import reservationService from "@/services/bar/reservation.service.js";
import TablesCalendar from "@/components/bar/calendar/TablesCalendar.vue";
import ReservationListSendSmsDialog from "@/components/bar/reservation/ReservationListSendSmsDialog";

export default {
  components: {
    MenuDialogCalendar,
    ReservationCreateDialog,
    ResponsiveCardSelector,
    DailyZonesDialog,
    CategoriesCalendar,
    TablesCalendar,
    ReservationListSendSmsDialog,
  },
  name: "ReservationsCalendar",

  data() {
    return {
      openMenu: false,
      openDailyZones: false,
      bus: new Vue(),

      loadingForm: false,
      zones: undefined,
      selectedZone: undefined,
      openReservationDialog: false,
      reservations: [],
      weekEnablings: 0,
      currentReservation: undefined,
      newReservation: {},
      openSendSmsDialog: false,
    };
  },
  mounted() {
    this.weekEnablings = new Date().getDay();

    this.fetchZones();
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openMenuFromReservation(reservation) {
      this.currentReservation = reservation;
      this.bus.$emit("reload-events");
      this.openReservationDialog = false;
      this.openMenu = true;
    },
    closeMenu() {
      this.openMenu = false;
    },
    openReservationMenu() {
      this.openMenu = true;
    },
    fetchZones() {
      zoneService.list().then((result) => {
        this.selectedZone = result[0];
        this.zones = result;
        this.fetchZoneTables(Number(this.selectedZone.id));
      });
    },
    fetchZoneTables() {
      return new Promise((resolve, reject) => {
        let zoneId = this.selectedZone ? this.selectedZone.id : 1;
        zoneService.getZoneTables(zoneId).then((result) => {
          result.sort(zoneActiveHandler.sortAlphaNum);
          resolve(result);
        });
      });
    },

    categoryFetcher(reservation) {
      return reservation.table.id
    },
    categorySetter(reservation, category) {
        reservation.table.id = category.id;
        reservation.tableId = category.id;
        reservation.table.code = category.code;
    },
    fetchReservationsForCategories(start, end) {
      return new Promise((resolve, reject) => {
        reservationService.list({reservationDate: end.toISOString()}).then((reservations) => {
          for (let i = 0; i < reservations.length; i++) {
            if (
              !!reservations[i].billReservation &&
              reservations[i].billReservation.bill.status == "closed"
            )
              reservations[i].locked = true;
            
            reservations[i].timed = !reservations[i].allDay;
            reservations[i].startDate = new Date(reservations[i].startDate);
            reservations[i].endDate = new Date(reservations[i].endDate);
          }
          this.reservations = reservations.filter(reservation => !!reservation.table);
      
          resolve(reservations.filter(reservation => !!reservation.table));
        });
      });
    },
    handleShowReservation(reservation) {
      if(this.disabled)
        return;

      if (
        Number(reservation.table.currentReservationId) ===
        Number(reservation.id)
      ) {
        this.currentReservation = reservation;
        this.openReservationMenu();
      } else {
        this.newReservation = reservation;
        if (reservation) {
          this.tableSelected = reservation.table;
          this.tableSelected.zone = this.selectedZone;
        }
        this.openReservationDialog = true;
      }
    },
    handleNewReservation(params) {
      if(this.disabled)
        return;

      this.newReservation = {
        reservationDate: new Date(params.startDate),
        startDate: new Date(params.startDate),
      };
      if (params) {
        this.tableSelected = params.category;
        this.tableSelected.zone = this.selectedZone;
      }
      this.openReservationDialog = true;
    },
    handleEndDrag(reservation) {
      if(this.disabled)
        return;

      if (reservation) {
        reservation.startDate = new Date(reservation.startDate);
        reservation.endDate = new Date(reservation.endDate);
        let reservationToUpdate = {
          id: reservation.id,
          tableId: reservation.tableId,
          startDate: reservation.startDate,
          endDate: reservation.endDate,
          tableCode: reservation.tableCode,
        };

        reservationService
          .update({ reservation: reservationToUpdate })
          .then(() => {
            this.bus.$emit("reload-events");
            this.$emit("reload-events");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    nameFetcher(reservation) {
      return reservation.reservationName;
    },
    colorFetcher(reservation) {
      return reservation.table.color ? reservation.table.color : "#a7f09c";
    },
    setDailyDate() {
      let date = new Date();
      date.setDate(this.weekEnablings);
      return date;
    },
    handleSaveReservation() {
      this.openReservationDialog = false;
      this.bus.$emit("reload-events");
      this.$emit("reload-events");
      this.reservationSelected = undefined;
      this.tableReservations = [];
    },
    handleUpdateReservation() {
      this.openReservationDialog = false;
      this.bus.$emit("reload-events");
      this.reservationSelected = undefined;
      this.tableReservations = [];
    },
    closeReservation() {
      this.bus.$emit("reload-events");
      this.$emit("reload-events");
      this.openReservationDialog = false;
      this.reservations = [];
    },
    handleChangeReservation(reservationSelected) {
      this.currentReservation = reservationSelected;
      this.openReservationMenu();
    },
  },
  computed: {
    selectedComponent() {
      if (!!this.selectedZone) {
        return this.selectedZone.name;
      } else {
        return "";
      }
    },
  },
  watch: {
    selectedZone(newVal) {
      if (newVal) {
        this.selectedZone = newVal;
        this.fetchZoneTables(Number(newVal.id));
      }
    },
    reservations(newVal) {
      this.reservations = newVal;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

