<template>
  <div>
    <v-card
      min-height="70vh"
      max-height="70vh"
      v-if="reservationsNotEmpty()"
      style="overflow-y: scroll; overflow-x: hidden"
    >
      <v-timeline dense v-for="code in tableCodes" :key="code">
        <div @click="showReservation" class="ma-3 font-weight-bold text-center">
          Prenotazioni Tavolo {{ code }}
        </div>
        <v-timeline-item
          :color="reservation.table.color"
          v-for="(reservation, i) in localReservations.filter(
            (i) => i.tableCode == code
          )"
          :key="i"
        >
          <v-row>
            <v-col cols="8">
              <strong>
                {{ reservation.reservationName }}
              </strong>
              {{ reservation.guests }}
              ospiti
              {{ _printDate(new Date(reservation.reservationDate)) }}
              Dalle ore
              {{ new Date(reservation.startDate).getHours() }}:{{
                new Date(reservation.startDate).getMinutes()
              }}
              alle ore
              {{ new Date(reservation.endDate).getHours() }}:{{
                new Date(reservation.endDate).getMinutes()
              }}
            </v-col>
            <v-col v-if="reservationDateToday(reservation)" cols="3">
              <v-btn
                @click="editReservation(reservation.id)"
                icon
                color="grey darken-2"
                class="ml-2"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                @click="deleteReservation(reservation, reservation.tableId)"
                icon
                color="grey darken-2"
                class="ml-2"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card>

    <div
      v-else
      class="font-weight-light font-italic text-center mt-6"
      style="width: 100%; font-size: 15px; font-weight: bold"
    >
      <strong>Nessuna prenotazione</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReservationList",
  components: {},
  props: {
    reservations: {
      type: Array,
      default: function () {
        return [];
      },
    },
    color: {
      type: String,
      default: "",
    },
  },
  mounted() {
    // v-timeline-item__inner-dot
    this.localReservations = this.reservations;
  },
  data: function () {
    return {
      localReservations: undefined,
      reservationDateString: "",
      tableCodes: [],
    };
  },
  methods: {
    showReservation() {},
    reservationsNotEmpty() {
      if (this.localReservations && this.localReservations.length > 0) {
        return true;
      } else return false;
    },
    changeColor(reservation) {
      let today = new Date();
      let dateToCompare = new Date(reservation.reservationDate);

      today.setHours(0, 0, 0, 0);
      dateToCompare.setHours(0, 0, 0, 0);

      if (dateToCompare.getTime() > today.getTime()) return "blue";
      else return "green";
    },
    reservationDateToday(reservation) {
      let today = new Date();
      let dateToCompare = new Date(reservation.reservationDate);

      today.setHours(0, 0, 0, 0);
      dateToCompare.setHours(0, 0, 0, 0);

      if (dateToCompare.getTime() == today.getTime()) return true;
      else return false;
    },
    editReservation(reservationId) {
      this.$emit("edit-reservation", reservationId);
    },
    deleteReservation(reservation, tableId) {
      let confirmed = confirm(
        "Vuoi davvero eliminare la prenotazione di " +
          reservation.reservationName +
          " ?"
      );
      if (confirmed) this.$emit("delete-reservation", reservation.id, tableId);
    },
    _printDate(date) {
      const months = [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ];

      const days = [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
      ];
      const year = date.getFullYear();
      const dayNumber = date.getDate();
      const monthName = months[date.getMonth()];
      const dayName = days[date.getDay()];

      return dayName + ", " + dayNumber + " " + monthName + " " + year;
    },
  },
  watch: {
    reservations(newVal) {
      let _reservationByTable = newVal.map((i) => {
        return i.table.code;
      });
      this.tableCodes = [...new Set(_reservationByTable)];
      if (newVal) this.localReservations = newVal;
    },
  },
};
</script>