<template>
  <div>
    <v-card
      color="background"
      min-height="87vh"
      max-height="87vh"
      height="87vh"
      style="overflow-y: scroll"
    >
      <v-card-text>
        <div style="font-size: 15px; font-weight: bold">
          Prenotazioni del giorno
          <DateTimeFormattor
            v-model="todayReservation"
            format="dd/mm/yyyy"
          ></DateTimeFormattor>
        </div>
        <v-list class="transparent">
          <v-divider></v-divider>
          <v-list-item
            class="ma-2"
            v-for="(reservation, y) in reservations"
            :key="y"
          >
            <v-list-item-content>
              <v-list-item-title>
                <DateTimeFormattor
                  :with-time="true"
                  :with-date="false"
                  v-model="reservation.startDate"
                  format="dd/mm/yyyy"
                ></DateTimeFormattor>
                <div
                  :style="{
                    'text-decoration': statusClosed(reservation)
                      ? 'line-through'
                      : '',
                  }"
                >
                  {{ reservation.reservationName }} x
                  {{ reservation.guests }}
                </div>
              </v-list-item-title>

              <v-list-item-subtitle v-if="!!reservation.table && reservation.zone">
                Tavolo
                {{ reservation.table.code }}
                in
                {{ reservation.zone.name }}
              </v-list-item-subtitle>

              <v-list-item-subtitle v-else>
                Comanda Al Banco
              </v-list-item-subtitle>

              <v-divider></v-divider>
            </v-list-item-content>
            <v-btn
              v-if="!statusClosed(reservation)"
              icon
              @click="handleSelectReservation(reservation)"
            >
              <v-icon v-if="hasReservation(reservation)">mdi-book-open-page-variant</v-icon>

              <v-icon v-else >mdi-arrow-up-bold-circle</v-icon>
            </v-btn>
            <v-btn v-else icon @click="handleOpenBill(reservation)">
              <v-icon>mdi-book-cancel</v-icon>
            </v-btn>
          </v-list-item>
          <v-divider></v-divider>
        </v-list>
      </v-card-text>
    </v-card>
    <DetailBillClosed
      v-if="openDetailBill"
      :open-dialog="openDetailBill"
      :bill="bill"
      @close="openDetailBill = false"
    ></DetailBillClosed>
  </div>
</template>

<script>
import DateTimeFormattor from "@/components/common/DateTimeFormattor";
import reservationService from "@/services/bar/reservation.service";
import Vue from "vue";
import DetailBillClosed from "./DetailBillClosed.vue";

export default {
  name: "DailyReservationList",
  components: {
    DateTimeFormattor,
    DetailBillClosed,
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
  },
  data() {
    return {
      todayReservation: undefined,
      reservations: [],
      openDetailBill: false,
      bill: {},
    };
  },
  mounted() {
    this.todayReservation = new Date()
    this.fetchReservations();
    let self = this;
    this.bus.$on('reload-reservations', self.fetchReservations)
  },
  methods: {
    statusClosed(reservation) {
      if (!!reservation.billReservation)
        return reservation.billReservation.bill.status == "closed";
    },
    hasReservation(reservation) {
      return !!reservation.billReservation
    },
    fetchReservations() {
      reservationService
        .list({ todayReservation: true })
        .then((reservations) => {
          this.reservations = reservations;
      });
    },
    handleSelectReservation(reservation) {
      this.bus.$emit("selection:reservation", reservation);
    },
    handleOpenBill(reservation) {
      this.bill = reservation.billReservation.bill;
      this.openDetailBill = true;
    },
  },
};
</script>

<style>
</style>