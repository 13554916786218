<template>
  <div>
    <ReservationsCalendar> </ReservationsCalendar>
  </div>
</template>

<script>
import ReservationsCalendar from  '@/components/bar/calendar/ReservationsCalendar'
export default {
  components:{ReservationsCalendar},
  name:"BarCalendarMinimalized",

}
</script>

<style>
</style>