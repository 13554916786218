<template>
  <div>
    <BarManagement></BarManagement>
  </div>
</template>

<script>
import BarManagement from "@/components/bar/BarManagement.vue";

export default {
  components: {
    BarManagement,
  },
};
</script>

<style>
</style>